import Gallery, { GalleryItem } from "../components/ProjectGallery";
import HeaderSubPages from "../components/HeaderSubPages";
import '../assets/css/Projects.css'
import data from "../data/progress-sr.json"
import sunIcon from '../assets/graphics/FSV_graphic-logosunwhite.png'

const liftVideoSeattleRidge = "https://cdn.bfldr.com/OPKE8V6B/as/m9bpgg2vkms7bj6mwwj285q/FSV_project_seattleridge_video"


const galleryImages = [
  { caption: "Seattle Ridge Lift Bottom Terminal", url: "https://cdn.bfldr.com/OPKE8V6B/at/pbgx46s57tx8sjv6h6rjqbx/FSV_project_seattleridge_plans_1.jpg?auto=webp&format=jpg", alt: "bottom of lift rendering" },
  { caption: "Seattle Ridge Lift Base Area", url: "https://cdn.bfldr.com/OPKE8V6B/at/fz2qgvx857gjhb2snznbh9sk/FSV_project_seattleridge_plans_2.jpg?auto=webp&format=jpg", alt: "bottom of lift downhill rendering" },
  { caption: "Top of Seattle Ridge Lift", url: "https://cdn.bfldr.com/OPKE8V6B/at/9ggf2cqhczssnksqkckxsgwr/FSV_project_seattleridge_plans_3.jpg?auto=webp&format=jpg", alt: "top terminal and chairs rendering of seattle ridge lift" },
  { caption: "Overhead Bottom Terminal View", url: "https://cdn.bfldr.com/OPKE8V6B/at/t53bf9mkk7ghmcsxmtxgs6fh/FSV_project_seattleridge_plans_4.jpg?auto=webp&format=jpg", alt: "bottom terminal overhead view of seattle ridge lift" }
];


function ProjectsSeattleRidge() {
  return (
    <div className='projects'>
      <div className="projects-header-seattleridge">
        <HeaderSubPages />
      </div>
      <div className="projects-subheader">
        <h2>Seattle Ridge Lift Replacement</h2>
        <div className="main-text">
          <p>Opened on December 20, 2024, the new Seattle Ridge lift offers intermediate runs that make family mountain days more fun and tree-skiing that challenges experts, Bald Mountain&apos;s Seattle Ridge area is getting the “lift” this special terrain deserves.</p>
        </div>
      </div>
      <div className="projects-video">
        <img src={sunIcon} alt="sun valley sun icon"></img>
        <video src={liftVideoSeattleRidge} controls></video>
      </div>
      <div className='hero-quote'>
        <div className='hero-quote-text'>
          <h2 className='quote1'>"</h2>
          <p>Seattle Ridge is home to some of Sun Valley&apos;s most beloved intermediate, family-friendly terrain and more recently with the Sunrise expansion, some of its best off-piste terrain. Over the last five years, we&apos;ve been able to increase the skiable terrain serviced by Seattle Ridge chair by over 200 acres, and we believe the chairlift upgrade is coming at the perfect time as we continue our investment in the mountain experience at Sun Valley.</p>
          <h2 className='quote2'>"</h2>
        </div>
        <h4>– Pete Sonntag, Vice President and General Manager of Sun Valley Resort</h4>
      </div>
      <Gallery>
        <GalleryItem><img src={galleryImages[0].url} alt={galleryImages[0].alt}></img><p>{galleryImages[0].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[1].url} alt={galleryImages[1].alt}></img><p>{galleryImages[1].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[2].url} alt={galleryImages[2].alt}></img><p>{galleryImages[2].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[3].url} alt={galleryImages[3].alt}></img><p>{galleryImages[3].caption}</p></GalleryItem>
      </Gallery>
      <div className="project-highlights-container">
        <div className="gold-line"></div>
        <div className='project-highlights' id="progress">
          <ul>
            <li><span className="bold">Up, up, & away.</span> With the construction of a new detachable six-person Doppelmayr chairlift, uphill capacity will increase in this area by 20 percent. Get ready to safely make more turns with even less wait time on all your favorite Seattle Ridge area runs.</li>
            <li><span className="bold">Go with the flow.</span> The new Seattle Ridge chairlift will also provide improved skier circulation to this side of Bald Mountain, creating a smoother flow of riders around the base area and across the hill.</li>
            <li><span className="bold">More to explore.</span> Through the Bald Mountain Stewardship program, 91 additional acres of glade terrain will be available to explore, meaning all-new adventures in the Sunrise area.</li>
            <li><span className="bold">Happy little trees.</span> Forest health is top of mind for all of us who know and love Bald Mountain. In partnership with the National Forest Foundation, US Forest Service, and Bureau of Land Management, the work conducted in the Sunrise region will reduce fire risk while improving forest health and preserving the unbeatable recreational experience.</li>
          </ul>
          <div className="project-highlights-image">
            <img src={sunIcon} alt="sun valley sun icon"></img>
          </div>
        </div>
        <div className="progressupdates-title">
          <ul>
            <li>Progress Updates</li>
          </ul>
        </div>
      </div>
      <div className="progressupdates">
        <div className="progressupdates-container">
          <div className="progressupdates-video">
            <iframe src={data[0].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          <div className="progress-link">
            <a href='/progress/seattleridge'>MORE UPDATES</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSeattleRidge;
