import '../assets/css/HeaderSubPages.css'
import { useLocation } from 'react-router-dom'
import historyHeader from '../assets/graphics/FSV_graphic-historyofsunvalley.png'
import wsHeader from '../assets/graphics/FSV_graphic-progress_warmsprings.png'
import srHeader from '../assets/graphics/FSV_graphic-progress_seattleridge.png'
import wsProjectHeader from '../assets/graphics/FSV_graphic-project_warmsprings.png'
import srProjectHeader from '../assets/graphics/FSV_graphic-project_seattleridge.png'
import svvProjectHeader from '../assets/graphics/FSV_graphic-project_sunvalleyvillage.png'

const headerText = {
  history: historyHeader,
  warmsprings: wsHeader,
  seattleridge: srHeader
}
const headerTextProjects = {
  warmsprings: wsProjectHeader,
  seattleridge: srProjectHeader,
  sunvalleyvillage: svvProjectHeader
}

export default function HeaderSubPages() {
  const path = useLocation().pathname;
  const location1 = path.split("/")[1];
  const location2 = path.split("/")[2];

  if (location1 === "projects") {
    return (
      <div className='headersubpages'>
        <div className='hero-image'>
          <div className='color-overlay'></div>
          <div className='hero-text'>
            <img src={headerTextProjects[location2]} alt="explore the future of sun valley"></img>
          </div>
        </div>
      </div>
    );
  }
  else if (location1 === "history") {
    return (
      <div className='headersubpages'>
        <div className='hero-image'>
          <div className='color-overlay'></div>
          <div className='hero-text'>
            <img src={headerText.history} alt="explore the future of sun valley"></img>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='headersubpages'>
      <div className='hero-image'>
        <div className='color-overlay'></div>
        <div className='hero-text'>
          <img src={headerText[location2]} alt="explore the future of sun valley"></img>
        </div>
      </div>
    </div>
  );
}