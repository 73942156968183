import Gallery, { GalleryItem } from "../components/ProjectGallery";
import HeaderSubPages from "../components/HeaderSubPages";
import '../assets/css/Projects.css'
import data from "../data/progress-ws.json"
import sunIcon from '../assets/graphics/FSV_graphic-logosunwhite.png'

const liftVideo = "https://cdn.bfldr.com/OPKE8V6B/as/qw4kqp25q33pctps99nrjn/WSEP_Re_Cap_V1_2732_MB?t=2"


const galleryImages = [
  { caption: "Project Overview Map", url: "https://cdn.bfldr.com/OPKE8V6B/at/ktvkhh6phv8mprqcwqntbrk6/Lift-Map_Updated3-30-23.jpg?auto=webp&format=jpg", alt: "trail map overview of project" },
  { caption: "Flying Squirrel Lift Top Terminal", url: "https://cdn.bfldr.com/OPKE8V6B/at/c78wjhcckr8x7sxtm4jwr4/FSV_project_warmsprings_plans_2.jpg?auto=webp&format=jpg", alt: "top terminal rendering of flying squirrel lift" },
  { caption: "Flying Squirrel Lift Top Terminal", url: "https://cdn.bfldr.com/OPKE8V6B/at/jp8ghhmk4gn45f3rv9mm2r/Lift_1A__Top_Terminal_1.jpg?auto=webp&format=jpg", alt: "top terminal rendering of flying squirrel lift" },
  { caption: "Flying Squirrel Lift Bottom Terminal", url: "https://cdn.bfldr.com/OPKE8V6B/at/cm64fsfz69jfbp9pg6fcr425/Lift_1A__Bottom_Terminal.jpg?auto=webp&format=jpg", alt: "bottom terminal rendering of flying squirrel lift" },
  { caption: "Challenger Top Terminal", url: "https://cdn.bfldr.com/OPKE8V6B/at/jpwmth53krmspmcbh9s7t43s/Challenger__Top_Terminal_1.jpg?auto=webp&format=jpg", alt: "challenger top terminal rendering" },
  { caption: "Challenger Top Terminal", url: "https://cdn.bfldr.com/OPKE8V6B/at/m4v38ghf8h5rmcf2h3w3vhk/Challenger__Top_Terminal_2.jpg?auto=webp&format=jpg", alt: "challenger top terminal rendering" },
  { caption: "Challenger Mid Station", url: "https://cdn.bfldr.com/OPKE8V6B/at/ftkhb99bjrr7gxwhxx3p4g7/Challenger__Mid-Station_Unload_1.jpg?auto=webp&format=png", alt: "challenger mid station rendering" },
  { caption: "Challenger Mid Station", url: "https://cdn.bfldr.com/OPKE8V6B/at/sp9ttqgbw7pq4wfq697vp6vr/FSV_project_warmsprings_plans_8.jpg?auto=webp&format=jpg", alt: "challenger mid station rendering" },
  { caption: "Warm Springs Base Terminals (Left: Flying Squirrel; Right: Challenger)", url: "https://cdn.bfldr.com/OPKE8V6B/at/23tshgck58swjwrc6nm8v8c/BaseTerminals.jpg?auto=webp&format=jpg", alt: "warm springs base terminals" },
];


function ProjectsWarmSprings() {
  return (
    <div className='projects'>
      <div className="projects-header-warmsprings">
        <HeaderSubPages />
      </div>
      <div className="projects-subheader">
        <h2>Warm Springs Enhancement</h2>
        <div className="main-text">
          <p>Opened on December 20, 2023, the Warm Springs Enhancement Project greatly improves skier circulation on the mountain, allowing skier traffic to flow over a wider area of skiable terrain. The upgrades improve lift quality, efficiency and sustainability and provide multiple options for accessing the mountain from the Warm Springs area.</p>
        </div>
      </div>
      <div className="projects-video">
        <img src={sunIcon} alt="sun valley sun icon"></img>
        <video src={liftVideo} controls></video>
      </div>
      <div className='hero-quote'>
        <div className='hero-quote-text'>
          <h2 className='quote1'>"</h2>
          <p>This undertaking has been one of the most ambitious mountain projects we've accomplished in many years, and I could not be more proud of our team and excited to show our guests what we've been hard at work on since last spring. These two new lift openings mark an exciting next chapter for Sun Valley Resort and we look forward to celebrating with our community, our guests and the entire Sun Valley team.</p>
          <h2 className='quote2'>"</h2>
        </div>
        <h4>– Pete Sonntag, Vice President and General Manager of Sun Valley Resort</h4>
      </div>
      <Gallery>
        <GalleryItem><img src={galleryImages[0].url} alt={galleryImages[0].alt}></img><p>{galleryImages[0].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[1].url} alt={galleryImages[1].alt}></img><p>{galleryImages[1].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[2].url} alt={galleryImages[2].alt}></img><p>{galleryImages[2].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[3].url} alt={galleryImages[3].alt}></img><p>{galleryImages[3].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[4].url} alt={galleryImages[4].alt}></img><p>{galleryImages[4].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[5].url} alt={galleryImages[5].alt}></img><p>{galleryImages[5].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[6].url} alt={galleryImages[6].alt}></img><p>{galleryImages[6].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[7].url} alt={galleryImages[7].alt}></img><p>{galleryImages[7].caption}</p></GalleryItem>
        <GalleryItem><img src={galleryImages[8].url} alt={galleryImages[8].alt}></img><p>{galleryImages[8].caption}</p></GalleryItem>
      </Gallery>
      <div className="project-highlights-container">
        <div className='project-highlights' id="progress">
          <ul>
            <li><span className="bold">Whisked away.</span> The Challenger and Greyhawk lifts have been replaced with a cutting-edge Doppelmayr 6-pack, covering a distance of 3,138 vertical feet in just 8 minutes. The new lift will honor the same name as its predecessor, Challenger, and will boast the largest vertical rise in North America.</li>
            <li><span className="bold">Streamlined access.</span> The Challenger replacement features a convenient mid-lift unload, which will replaces the old Greyhawk lift and streamlines access to the Greyhawk terrain and popular race venues.</li>
            <li><span className="bold">Reintroducing Flying Squirrel.</span> Having lost the former lift to fire in 2014, the new Flying Squirrel chairlift, a Doppelmayr 4-pack, transports skiers from the Warm Springs base to mid-mountain, offering access to terrain of all difficulty levels and the River Run base area. Advanced skiers can now conveniently reach Warm Springs via the newly extended Lower Flying Squirrel trail.</li>
            <li><span className="bold">Making better connections.</span> Combined, these new lifts not only improve skier circulation, they also offer simplified ways to access other regions of the mountain. Explore more of Bald Mountain with the ability to easily return to your Warm Springs home base.</li>
          </ul>
          <div className="project-highlights-image">
            <img src={sunIcon} alt="sun valley sun icon"></img>
          </div>
        </div>
      </div>

      <div className="chairlift-stats">
        <h2>Warm Springs Enhancement Details</h2>
        <div className="sidebyside-container">
          <div className="sidebyside-text">
            <h3>Challenger Lift</h3>
            <p>Introducing the first D-line chairlift with a mid-station in North America.</p>
            <ul>
              <li><span className="bold">Slope Length:</span> 8,693 feet</li>
              <li><span className="bold">Vertical Rise:</span> 3,138 feet — the biggest vertical rise chairlift in North America</li>
              <li><span className="bold">Lift Time (bottom to top):</span> 8 minutes</li>
              <li><span className="bold">Lift Time (bottom to mid-station):</span> 3 minutes, 20 seconds</li>
              <li><span className="bold">Motor Size:</span> 1,690 Horsepower</li>
              <li><span className="bold">Speed:</span> 1,181 feet per minute</li>
              <li><span className="bold">Capacity:</span> 2,465 people per hour</li>
              <li><span className="bold">Chair Lifts & Towers:</span> 109 Chairs and 28 Towers</li>
            </ul>
          </div>
          <div className="sidebyside-image">
            <img src="https://cdn.bfldr.com/OPKE8V6B/at/mkmvpjqncqbhkhq3mpg6qfh/FSV_project_warmsprings_hero.jpg?auto=webp&format=jpg" alt="chairlift with mountain view"></img>
          </div>
        </div>
        <div className="sidebyside-container-right">
          <div className="sidebyside-text">
            <h3>Flying Squirrel Lift</h3>
            <p>A Doppelmayr 4-pack, Flying Squirrel transports skiers from the Warm Springs base to mid-mountain, offering access to terrain of all difficulty levels.</p>
            <ul>
              <li><span className="bold">Slope Length:</span> 6,252 feet</li>
              <li><span className="bold">Vertical Rise:</span> 2,353 feet</li>
              <li><span className="bold">Lift Time (bottom to top):</span> 6 minutes, 15 seconds</li>
              <li><span className="bold">Motor Size:</span> 800 Horsepower</li>
              <li><span className="bold">Speed:</span> 1,000 feet per minute</li>
              <li><span className="bold">Capacity:</span> 1,795 people per hour</li>
              <li><span className="bold">Chair Lifts & Towers:</span> 98 Chairs and 21 Towers</li>
            </ul>
          </div>
          <div className="sidebyside-image-left">
            <img src="https://cdn.bfldr.com/OPKE8V6B/at/599559fk2bk83rn353sjsfz/FSV_project_warmsprings_details_2.jpg?auto=webp&format=jpg&width=1000&height=670" alt="overhead view of chairlift on snowy slope"></img>
          </div>
        </div>
        <div className="sidebyside-container">
          <div className="sidebyside-text">
            <h3>New Lower Flying Squirrel Trail (Opening Soon)</h3>
            <p>Advanced skiers will be able to conveniently reach Warm Springs via the newly extended Lower Flying Squirrel trail.</p>
            <ul>
              <li><span className="bold">Rating:</span> Black Diamond</li>
              <li><span className="bold">Pitch:</span> 38% slope; 21.8 degrees</li>
              <li><span className="bold">Vertical:</span> 1,500 feet</li>
              <li><span className="bold">Snowmaking Towers:</span> 14</li>
            </ul>
          </div>
          <div className="sidebyside-image">
            <img src="https://cdn.bfldr.com/OPKE8V6B/at/wkv9qmzpp9b4skbhstvt7z/FSV_home_hero.jpg?auto=webp&format=jpg" alt="skier on steep snowy slope"></img>
          </div>
        </div>
      </div>


      <div className="progressupdates">
        <h2>Progress Updates</h2>
        <p>Watch the Warm Springs Enhancement Project unfold over an incredible 35 weeks.</p>
        <div className="progressupdates-container">
          <div className="progressupdates-video">
            <iframe src={data[0].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          <div className="progressupdates-video">
            <iframe src={data[1].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          <div className="progress-link">
            <a href='/progress/warmsprings'>MORE UPDATES</a>
          </div>
        </div>
      </div>

    </div>

  );
}

export default ProjectsWarmSprings;
