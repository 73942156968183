import HeaderSubPages from "../components/HeaderSubPages";
import '../assets/css/History.css'
import ModalImage from "react-modal-image";

const historyImages1 = [
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/xhm62hc9cx978qztw2sfmm/FSV_history_hero.jpg?auto=webp&format=jpg", alt: "Winter at Sun Valley Lodge" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/4w8jj9n6tg497x9wcwc8cgxc/FSV_history_ItAllStartedHere_2.jpg?auto=webp&format=jpg", alt: "Summer at Sun Valley" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/fkrvbvnp5k945kkmsjqjs/FSV_history_ItAllStartedHere_3.jpg?auto=webp&format=jpg", alt: "Averell Harriman Skiing Sun Valley" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/qbg4rf7rb466kgsj3f6b33w/FSV_history_BrassRanch_1.jpg?auto=webp&format=jpg", alt: "Roberta Brass on Horseback" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/b43qq7ngssrcpr6z689r5t/FSV_history_BrassRanch_2.jpg?auto=webp&format=jpg", alt: "Brass Ranch in the summer" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/87r7mzffhjk5nmqmhpjs2j5f/FSV_history_ProctorMountain_1.jpg?auto=webp&format=jpg", alt: "Proctor Ski Lift" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/r4jvp8w5cs7psxsmnjpv7vjc/FSV_history_ProctorMountain_2.jpg?auto=webp&format=jpg", alt: "Proctor Mountain T-Bar" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/3gmkthbn8qwjf66hq9p6mfk7/FSV_history_WorldFirstChairlift_1.jpg?auto=webp&format=jpg", alt: "Skiers Getting Ready to Get on Proctor Lift" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/79f6jxxrwqxkjcw3fmk3hcw/FSV_history_WorldFirstChairlift_2.jpg?auto=webp&format=jpg", alt: "First Baldy Chairlift" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/mtm39xv9vnsjhx3mj4nr33z/FSV_history_WorldFirstChairlift_3.jpg?auto=webp&format=jpg", alt: "Chair Test in the Railyard" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/vxc7qxnwt3bv9ft77t2zr857/FSV_history_WorldFirstChairlift_4.jpg?auto=webp&format=jpg", alt: "Averell Harriman Riding a Ski Lift" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/wwn7tj4hrssvswwkbqtfmn/FSV_history_Celebrities_1.jpg?auto=webp&format=jpg", alt: "Marilyn Monroe" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/2mc5jttsqsqgvb3jm6p3qfx/FSV_history_Celebrities_2.jpg?auto=webp&format=jpg", alt: "Gary Cooper and Friends Skiing" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/2zcz59hh93338wkb57m5sn93/FSV_history_Celebrities_3.jpg?auto=webp&format=jpg", alt: "Ernest Hemingway" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/pjh5bx5qm38cc9qk4w5fpv6/FSV_history_FourSeasons_1.jpg?auto=webp&format=jpg", alt: "Girls Out Biking" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/ps9xfwcbxbsk49h9rmqwmcs/FSV_history_FourSeasons_2.jpg?auto=webp&format=jpg", alt: "Wallace and Wright Golfing" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/9659kjk7r9phbs2pc4k7k5h/FSV_history_FourSeasons_3.jpg?auto=webp&format=jpg", alt: "TCC Breakfast Horseback Ride" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/hm7spsk7c6mqfcmhnbhgct6/FSV_history_FourSeasons_4.jpg?auto=webp&format=jpg", alt: "Ice Skating at Sun Valley" },
];

const historyImages2 = [
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/fbnbgr3nfqp7r6g5khs9mj3/FSV_history_SkiSchool_1.jpg?auto=webp&format=jpg", alt: "Ski Race Finish Line" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/pp8nphgx8n3339sqk4965jw/FSV_history_SkiSchool_2.jpg?auto=webp&format=jpg", alt: "Ski Instructor Teaching a New Skier" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/psgqkxrpvjqjgkrf78fqx3/FSV_history_SkiSchool_3.jpg?auto=webp&format=jpg", alt: "Sigi Engl and Skier Jumping" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/kzjp5xrrrf6mnvwq89jcv8/FSV_history_SkiSchool_4.jpg?auto=webp&format=jpg", alt: "Janet Leigh and Sigi Engl" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/8n2h7nnh7t9b7kn8cfvw3wcq/FSV_history_Challenger_1.jpg?auto=webp&format=jpg", alt: "Horsedrawn Sleigh at Challenger Inn" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/jpq5mbrrhfgwxt8846s8mbx/FSV_history_Challenger_2.jpg?auto=webp&format=jpg", alt: "Ski School at The Ram" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/336p8r5tm5v33rtfv3cgwbs/FSV_history_Challenger_3.jpg?auto=webp&format=jpg", alt: "The Ram Trio" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/bs7n9wpwjvn6kcm9xg3p67/FSV_history_WarrenMiller_1.jpg?auto=webp&format=jpg", alt: "Warren Miller" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/v35s3p9tc3smn7jpzm9sp/FSV_history_WarrenMiller_2.jpg?auto=webp&format=jpg", alt: "Warren Miller and Family" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/k945fmhsghfz44g9jmcrcnh/FSV_history_SkiRacing_1.jpg?auto=webp&format=jpg", alt: "Womens Ski Team" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/8bjtkhsjvj64n87f8sv4ntgx/FSV_history_SkiRacing_2.jpg?auto=webp&format=jpg", alt: "Otto Lang Ski Racing" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/jht92mh8hq7kx4sj496gqhnt/FSV_history_SkiRacing_3.jpg?auto=webp&format=jpg", alt: "Gretchen Fraser" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/wth557st3hj4wj6494tw24f4/FSV_history_SkiRacing_4.jpg?auto=webp&format=jpg", alt: "Dick Buek Ski Racing" },
];

const historyImages3 = [
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/c4q45rgksfxhx689t8wwvws/FSV_history_FamilyOwned_1.jpg?auto=webp&format=jpg", alt: "Earl and Carol Holding" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/hps8fcnc5n2t2gwwhrbg9sc/FSV_history_FamilyOwned_2.jpg?auto=webp&format=jpg", alt: "The Holding Family" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/kg4m5fv74xx6hvqxm92sfn/FSV_history_BaldMountain_1.jpg?auto=webp&format=jpg", alt: "Sunrise Bowl" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/vw29447rbzrg9g5bjg3fmq99/FSV_history_BaldMountain_2.jpg?auto=webp&format=jpg", alt: "New Gladed Skiing at Bald Mountain" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/j7phgx5pgmqnnxtv7386w86/FSV_history_PassPartnership_1.jpg?auto=webp&format=jpg", alt: "Corduroy Carving" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/mvp8h92xx3v4wsggbtwxkjvk/FSV_history_PassPartnership_2.jpg?auto=webp&format=jpg", alt: "Views of Sun Valley" },
  { url: "https://cdn.bfldr.com/OPKE8V6B/at/4phm34vtxt9mgf4n6m82t6j/FSV_history_PassPartnership_3.jpg?auto=webp&format=jpg", alt: "Chairlift Views" },
];

function History() {
  return (
    <div className="history">
      <div className="history-header">
        <HeaderSubPages />
      </div>
      <div className="timeline-line"></div>
      <div className="title-year-bullet"></div>
      <div className="title-year">
        <h3>1935&ndash;2022</h3>
      </div>
      <div className="history-container">
        <div className="timeline-1930">
          <div className="timeline-header">
            <h2>1930s</h2>
            <div class="timeline-bullet"></div>
          </div>
          <div className="timeline-content">
            <h3>In the Beginning</h3>
            <h4>It All Started Here</h4>
            <p>Sun Valley&apos;s long and fabled history began in 1936 when Averell Harriman, the chairperson of the Union-Pacific Railroad, decided to establish the first destination ski resort in North America. With a vision of modeling the resort after European ski areas, he enlisted the expertise of Count Felix Schaffgotsch, a renowned Austrian ski instructor. Schaffgotsch searched far and wide across the West. When he came upon Ketchum, Idaho, a mining town surrounded by majestic mountain ranges, he knew he had found the ideal location for an exceptional ski experience</p>
            <div className="history-images">
              <ModalImage
                small={historyImages1[0].url}
                large={historyImages1[0].url}
                alt={historyImages1[0].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[1].url}
                large={historyImages1[1].url}
                alt={historyImages1[1].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[2].url}
                large={historyImages1[2].url}
                alt={historyImages1[2].alt}
                hideDownload="true"
                hideZoom="true"
              />
            </div>
          </div>
          <div className="timeline-content">
            <h4>Brass Ranch</h4>
            <p>The land that would become Sun Valley was once known as Brass Ranch. When Schaffgotsch arrived in Ketchum, he met Roberta Brass, whose father owned a 3200-acre ranch in the area. Brass Ranch was purchased from the family at about $4 per acre, and resort construction started the following spring, in May 1936. Gilbert, Stanley, Underwood and Company, an architect firm based out of Los Angeles, was enlisted, and after seven months, the Sun Valley Lodge and resort opened on December 21, 1936.</p>
            <div className="history-images">
              <ModalImage
                small={historyImages1[3].url}
                large={historyImages1[3].url}
                alt={historyImages1[3].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[4].url}
                large={historyImages1[4].url}
                alt={historyImages1[4].alt}
                hideDownload="true"
                hideZoom="true"
              />
            </div>
          </div>
          <div className="timeline-content">
            <h4>Proctor Mountain</h4>
            <p>The ski runs at Sun Valley were designed by Charles Proctor. Proctor was one of America's foremost ski pioneers and had been a ski racing star at Dartmouth. Proctor Mountain, northeast of Bald Mountain, takes its name from him. Still a popular area for hiking and trail running, Proctor Mountain also features remnants of the lower terminal of the world&apos;s first chairlift.</p>
            <div className="history-images">
              <ModalImage
                small={historyImages1[5].url}
                large={historyImages1[5].url}
                alt={historyImages1[5].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[6].url}
                large={historyImages1[6].url}
                alt={historyImages1[6].alt}
                hideDownload="true"
                hideZoom="true"
              />
            </div>
          </div>
          <div className="timeline-content">
            <h4>World's First Chairlift</h4>
            <p>Now that the ideal resort location had been found, efforts to further revolutionize the ski experience began. At the time, the only way for skiers to return to the peak of the mountain after a run was by strenuous hiking or rope tow. Jim Curran, a Union Pacific engineer, used his experience engineering banana conveyor systems to imagine a new way to transport skiers safely and speedily. Equipped with Curran's ingenious design, construction began on both Dollar and Proctor Mountains in the summer of 1936, and a few months later, Sun Valley became the birthplace of the modern chairlift.</p>
            <div className="history-images">
              <ModalImage
                small={historyImages1[7].url}
                large={historyImages1[7].url}
                alt={historyImages1[7].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[8].url}
                large={historyImages1[8].url}
                alt={historyImages1[8].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[9].url}
                large={historyImages1[9].url}
                alt={historyImages1[9].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[10].url}
                large={historyImages1[10].url}
                alt={historyImages1[10].alt}
                hideDownload="true"
                hideZoom="true"
              />
            </div>
          </div>
          <div className="timeline-content">
            <h4>Celebrity Getaway</h4>
            <p>As Harriman developed plans for Sun Valley, he enlisted the expertise of Steve Hannigan, a publicity maven out of Miami, Florida. Hannigan was instrumental in introducing the new resort to the world. He is credited for naming Sun Valley and branding it with its first tagline: “Winter sports under a summer sun.” It was also Hannigan's idea to bring Hollywood royalty to the resort. Several notable films used the areas in and around Sun Valley as a backdrop, including “Bus Stop” starring Marilyn Monroe, “Sun Valley Serenade” featuring Sonja Henie, “I Met Him in Paris” with Claudette Colbert, and Clint Eastwood's “Pale Rider.” <br /> Perhaps the celebrity whose identity is most intertwined with the resort, Ernest Hemingway spent twenty years living in Sun Valley on and off, and is even laid to rest in the Sun Valley cemetery. Hemingway first visited in 1939, and quickly took to writing in Room 206, which is where he finished his novel, “For Whom the Bell Tolls.” Though he enjoyed the serenity of the resort for his writing, he also spent time hunting and fishing in the outdoor paradise surrounding the Wood River Valley.</p>
            <div className="history-images">
              <ModalImage
                small={historyImages1[11].url}
                large={historyImages1[11].url}
                alt={historyImages1[11].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[12].url}
                large={historyImages1[12].url}
                alt={historyImages1[12].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[13].url}
                large={historyImages1[13].url}
                alt={historyImages1[13].alt}
                hideDownload="true"
                hideZoom="true"
              />
            </div>
          </div>
          <div className="timeline-content">
            <h4>A Four Season Resort</h4>
            <p>While the foremost objective was to introduce mountain sports to a wider American audience, the Sun Valley founders quickly realized that the magic of the resort wasn't just reserved for winter. That's why, beginning in the summer of 1937, Sun Valley became a year-round destination, offering various activities over the years such as golf, archery and shooting, biking, horse-back riding, ice skating in the summer, bowling, and even hosted a rodeo at one time.</p>
            <div className="history-images">
              <ModalImage
                small={historyImages1[14].url}
                large={historyImages1[14].url}
                alt={historyImages1[14].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[15].url}
                large={historyImages1[15].url}
                alt={historyImages1[15].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[16].url}
                large={historyImages1[16].url}
                alt={historyImages1[16].alt}
                hideDownload="true"
                hideZoom="true"
              />
              <ModalImage
                small={historyImages1[17].url}
                large={historyImages1[17].url}
                alt={historyImages1[17].alt}
                hideDownload="true"
                hideZoom="true"
              />
            </div>
          </div>

          <div className="timeline-1940">
            <div className="timeline-header">
              <h2>1940s&ndash;1970s</h2>
              <div class="timeline-bullet"></div>
            </div>
            <div className="timeline-content">
              <h3>A Ski Culture is Born</h3>
              <h4>The Best Ski School in the Country</h4>
              <p>Keeping in line with the European-style experience Harriman envisioned, a slew of Austrian ski instructors came to Sun Valley to teach. To this day, Sun Valley has continued a tradition of world-class ski instruction. Each season, the Sun Valley SnowSports school leads the way with certified instructors and leading-edge techniques, including the Terrain that Teaches program. <br /> Some of legendary instructors in Sun Valley's history include Otto Lang, who came from Austria in 1936  and took over the ski school in 1942. He later went on to open ski schools at Mt. Rainer, Mt. Baker, and Mt. Hood, and eventually became a famous film director. Sigi Engl, who headed the ski school in 1952, originally came to the U.S. from Kitzbühel. Engl also worked as Gary Cooper's private ski instructor. Another notable head of the Austrian-led ski school was Sepp Froelich.</p>
              <div className="history-images">
                <ModalImage
                  small={historyImages2[0].url}
                  large={historyImages2[0].url}
                  alt={historyImages2[0].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[1].url}
                  large={historyImages2[1].url}
                  alt={historyImages2[1].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[2].url}
                  large={historyImages2[2].url}
                  alt={historyImages2[2].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[3].url}
                  large={historyImages2[3].url}
                  alt={historyImages2[3].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
              </div>
            </div>
            <div className="timeline-content">
              <h4>The Challenger Inn &amp; The Ram</h4>
              <p>One year after the Resort's opening, the Challenger Inn was built. Now known as the Sun Valley Inn, it is home to the historic restaurant, The Ram. Though it was recently renovated in 2018, the Ram retains the same charming Austrian atmosphere it has been welcoming guests with since its opening. </p>
              <div className="history-images">
                <ModalImage
                  small={historyImages2[4].url}
                  large={historyImages2[4].url}
                  alt={historyImages2[4].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[5].url}
                  large={historyImages2[5].url}
                  alt={historyImages2[5].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[6].url}
                  large={historyImages2[6].url}
                  alt={historyImages2[6].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
              </div>
            </div>
            <div className="timeline-content">
              <h4>Warren Miller</h4>
              <p>Legendary filmmaker, Warren Miller, got his start in the parking lots of Sun Valley. Miller's ski films have been encouraging skiers to take to the slopes for over 70 years, and are still going strong. An important catalyst for Miller's career, Sun Valley was featured in his 2016 film, “Here, There, and Everywhere,” which follows professional skier Collin Collins around the Resort, recreating the historic start to Miller's career.</p>
              <div className="history-images">
                <ModalImage
                  small={historyImages2[7].url}
                  large={historyImages2[7].url}
                  alt={historyImages2[7].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[8].url}
                  large={historyImages2[8].url}
                  alt={historyImages2[8].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
              </div>
            </div>
            <div className="timeline-content">
              <h4>Ski Racing</h4>
              <p>The challenging terrain of Bald Mountain and the ideal learning terrain of Dollar Mountain have and continue to foster the talents of the best ski racers and snowboarders in the country. Home to numerous Olympians, Sun Valley proudly supports the Sun Valley Ski Education Foundation (SVSEF), and is committed to hosting a variety of racing events, from weekly town series races to, most recently, the US Alpine National Championships in 2016, 2018 and forthcoming in 2023 and 2024..</p>
              <div className="history-images">
                <ModalImage
                  small={historyImages2[9].url}
                  large={historyImages2[9].url}
                  alt={historyImages2[9].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[10].url}
                  large={historyImages2[10].url}
                  alt={historyImages2[10].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[11].url}
                  large={historyImages2[11].url}
                  alt={historyImages2[11].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages2[12].url}
                  large={historyImages2[12].url}
                  alt={historyImages2[12].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
              </div>
            </div>
          </div>

          <div className="timeline-1940">
            <div className="timeline-header">
              <h2>2000s</h2>
              <div class="timeline-bullet"></div>
            </div>
            <div className="timeline-content">
              <h3>An Eye on the Future</h3>
              <h4>Family Owned &amp; Independent</h4>
              <p>Over its storied, decades-long history, Sun Valley has only been owned and operated by three different owners. Still independently owned and operated to date, Sun Valley prides itself on providing the highest level of guest satisfaction, demonstrating a keen attention to detail, and continuing to craft memorable experiences for families that have made Sun Valley a part of their traditions for generations. 1936&ndash;1964 / Union Pacific Railroad 1965&ndash;1976 / Bill Janss, Janss Corporation 1977&ndash;present / The Holding Family.</p>
              <div className="history-images">
                <ModalImage
                  small={historyImages3[0].url}
                  large={historyImages3[0].url}
                  alt={historyImages3[0].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages3[1].url}
                  large={historyImages3[1].url}
                  alt={historyImages3[1].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
              </div>
            </div>
            <div className="timeline-content">
              <h4>Bald Mountain Expansion</h4>
              <p>As a part of the on-going commitment to providing the best skier experience, the resort's oldest chairlift, Cold Springs #4, was recently replaced with a high-speed detachable quad in Summer 2020. The additional 380 acres of skiable terrain was made available to the public for the Winter 2020-21 season.</p>
              <div className="history-images">
                <ModalImage
                  small={historyImages3[2].url}
                  large={historyImages3[2].url}
                  alt={historyImages3[2].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages3[3].url}
                  large={historyImages3[3].url}
                  alt={historyImages3[3].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
              </div>
            </div>
            <div className="timeline-content">
              <h4>Pass Partnerships</h4>
              <p>In 2022, Sun Valley ranked number one in SKI Magazine's reader survey for the third consecutive year. And, after three years of guest-recognized excellence, Sun Valley welcomed Ikon and Mountain Collective Pass holders to the resort beginning in the 2022-23 season. Sun Valley Resort appreciates these important partnerships as they create benefits for Sun Valley Pass Holders and continue to introduce new skiers and riders to the resort.</p>
              <div className="history-images">
                <ModalImage
                  small={historyImages3[4].url}
                  large={historyImages3[4].url}
                  alt={historyImages3[4].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages3[5].url}
                  large={historyImages3[5].url}
                  alt={historyImages3[5].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
                <ModalImage
                  small={historyImages3[6].url}
                  large={historyImages3[6].url}
                  alt={historyImages3[6].alt}
                  hideDownload="true"
                  hideZoom="true"
                />
              </div>
              <a href='/'>EXPLORE FUTURE PLANS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;